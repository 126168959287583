import { useStaticQuery, graphql } from 'gatsby';

const KarriereDataQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "Karriere-page" } } }
        ) {
          nodes {
            frontmatter {
              karriereContentApplicants {
                headline
                contentWenn
                contentDann
                contentPdf
              }
              karriereContentEasyApplication {
                content
                headline
                image {
                  childImageSharp {
                    fluid(maxWidth: 526, quality: 92) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
                subheadline
              }
              karriereContentExpectations {
                headline
                content {
                  descriptionAuge
                  descriptionTeamwork
                  descriptionMedallie
                  descriptionBike
                }
                image {
                  childImageSharp {
                    fluid(maxWidth: 526, quality: 92) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
                skewheadline
              }
              karriereUserStories {
                headline
                content
                image {
                  childImageSharp {
                    fluid(maxWidth: 526, quality: 92) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
              }
              image {
                childImageSharp {
                  fluid(maxWidth: 526, quality: 92) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
              pageDescription
              pageTitle
              seoKeywords
              title
              subtitle
              featuredImage {
                childImageSharp {
                  resize(width: 900) {
                    src
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark;
};

export default KarriereDataQuery;
