import React from 'react';
import PropTypes from 'prop-types';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { useLocation } from '@reach/router';
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema, DeviceSizes, CvtBasicTheme } from '../../global';
import { HtmlContent } from '../../components/basic/html-content';
import { BlockTop } from '../../components/cvt/layout/block-top';
import { BlockStandard } from '../../components/cvt/layout/block-standard';

/*---Material---*/
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExtensionIcon from '@mui/icons-material/Extension';

/*---Queries---*/
import KarriereDataQuery from '../../utility/data/karriere/karriere-query';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import CardKarriereHexagons from '../../templates/Karriere-page/Karriere_comps/cardKarriereHexagons';
import { LinkComponent } from '../../components/linkComponent';
import UserStories from '../../components/userStories';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const KarrierePageTemplate = ({
  title,
  subtitle,
  image,
  karriereContentApplicants,
  karriereContentExpectations,
  karriereContentEasyApplication,
  karriereUserStories,
}: {
  title: string;
  subtitle: string;
  image: any;
  karriereContentApplicants: any;
  karriereContentExpectations: any;
  karriereContentEasyApplication: any;
  karriereUserStories: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: 'white',
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />
      <h2>{karriereContentApplicants.headline}</h2>
      <br />
      <HtmlContent
        dangerouslySetInnerHTML={md.render(
          karriereContentApplicants.contentWenn
        )}
        style={CvtBasicTheme.htmlContent.general}
      />
      <br />
      <HtmlContent
        dangerouslySetInnerHTML={md.render(
          karriereContentApplicants.contentDann
        )}
        style={CvtBasicTheme.htmlContent.general}
      />
      <br />
      <HtmlContent
        dangerouslySetInnerHTML={md.render(
          karriereContentApplicants.contentPdf
        )}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />

      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!karriereContentExpectations.image.childImageSharp
              ? karriereContentExpectations.image.childImageSharp.fluid.src
              : karriereContentExpectations.image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          {karriereContentExpectations.headline}
        </h1>
        <div style={{ height: '70px', width: '70px' }}>
          <DirectionsBikeIcon />
        </div>
        <div>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              karriereContentExpectations.content.descriptionBike
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
        <div style={{ height: '70px', width: '70px' }}>
          <VisibilityIcon />
        </div>
        <div>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              karriereContentExpectations.content.descriptionAuge
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
        <div style={{ height: '70px', width: '70px' }}>
          <ExtensionIcon />
        </div>
        <div>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              karriereContentExpectations.content.descriptionTeamwork
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
        <div>Medallie: </div>
        <div>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              karriereContentExpectations.content.descriptionMedallie
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!karriereContentEasyApplication.image.childImageSharp
              ? karriereContentEasyApplication.image.childImageSharp.fluid.src
              : karriereContentEasyApplication.image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h3
          style={{
            color: 'black',
          }}
        >
          {karriereContentEasyApplication.subheadline}
        </h3>
        <h1
          style={{
            color: 'black',
          }}
        >
          {karriereContentEasyApplication.headline}
        </h1>
        <p style={{ color: 'black' }}>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              karriereContentEasyApplication.content
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </p>
      </div>
      <hr />
      {karriereUserStories.map((item: any, index: number) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3>{item.headline}</h3>
                <p>{item.content}</p>
              </div>
              <div>
                <img
                  src={item.image}
                  alt={'image' + index}
                  width="300"
                  height="auto"
                />
              </div>
            </div>
            <hr />
          </>
        );
      })}

      <hr />
      <footer
        style={{
          backgroundColor: '#eeeeee',
        }}
      >
        Footer
      </footer>
    </section>
  );
};

KarrierePageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  karriereContentApplicants: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  karriereContentExpectations: PropTypes.shape({
    headline: PropTypes.string,
    skewheadline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.shape({
      descriptionAuge: PropTypes.string,
      descriptionTeamwork: PropTypes.string,
      descriptionMedallie: PropTypes.string,
      descriptionBike: PropTypes.string,
    }),
  }),
  karriereContentEasyApplication: PropTypes.shape({
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.string,
  }),
  karriereUserStories: PropTypes.array,
};

export const KarriereMainPage = () => {
  const styles = {
    containerWennDann: {
      display: 'flex',
      flexDirection: 'row',
      [`@media(max-width: ${DeviceSizes.laptop})`]: {
        flexDirection: 'column',
      },
    },
    containerWenn: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '2em',
      [`@media(max-width: ${DeviceSizes.laptop})`]: {
        paddingRight: '0em',
      },
    },
    containerDann: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '2em',
      [`@media(max-width: ${DeviceSizes.laptop})`]: {
        paddingLeft: '0em',
      },
    },
  } as const;
  const karriereData = KarriereDataQuery();
  const { frontmatter } = karriereData.nodes[0];
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={12}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.white}
      />
      <BlockStandard
        headline={frontmatter.karriereContentApplicants.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        bgColor={CvtColorSchema.white}
        zIndex={11}
        padding={PaddingObj.PaddingIdle}
      >
        <Box component="div" sx={styles.containerWennDann}>
          <Box component="div" sx={styles.containerWenn}>
            <HtmlContent
              dangerouslySetInnerHTML={md.render(
                frontmatter.karriereContentApplicants.contentWenn
              )}
              style={CvtBasicTheme.htmlContent.general}
            />
          </Box>
          <Box component="div" sx={styles.containerDann}>
            <HtmlContent
              dangerouslySetInnerHTML={md.render(
                frontmatter.karriereContentApplicants.contentDann
              )}
              style={CvtBasicTheme.htmlContent.general}
            />
          </Box>
        </Box>
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.karriereContentApplicants.contentPdf
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <br />
      </BlockStandard>

      <BlockStandard
        headline={frontmatter.karriereContentExpectations.headline}
        color={CvtColorSchema.white}
        padding={PaddingObj.PaddingIdle}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        bgImage={
          !frontmatter.karriereContentExpectations.image.childImageSharp &&
          frontmatter.karriereContentExpectations.image.extension === 'svg'
            ? frontmatter.karriereContentExpectations.image.publicURL
            : null
        }
        zIndex={10}
      >
        <CardKarriereHexagons
          description={frontmatter.karriereContentExpectations.content}
        />
      </BlockStandard>
      <BlockStandard
        headline={frontmatter.karriereContentEasyApplication.headline}
        subheadline={frontmatter.karriereContentEasyApplication.subheadline}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.karriereContentEasyApplication.image.childImageSharp &&
          frontmatter.karriereContentEasyApplication.image.extension === 'svg'
            ? frontmatter.karriereContentEasyApplication.image.publicURL
            : null
        }
        zIndex={9}
        padding={PaddingObj.PaddingIdle}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.karriereContentEasyApplication.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <LinkComponent linkTo={SYSTEM_STRINGS.LINKS.INTERN.UNTERNEHMEN}>
          {SYSTEM_STRINGS.GENERAL.WORDS.HERE}
        </LinkComponent>
      </BlockStandard>

      {UserStories(frontmatter.karriereUserStories, 6)}
    </Layout>
  );
};
