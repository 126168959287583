import React from 'react';

import { Router } from '@reach/router';
import { KarriereMainPage } from '../../templates/Karriere-page/Karriere-page';

const KarrierePage: React.FC = () => {
  return (
    <Router>
      <KarriereMainPage path="/Karriere/" />
    </Router>
  );
};

export default KarrierePage;
