import React, { CSSProperties } from 'react';
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema, DeviceSizes, CvtBasicTheme } from '../../../global';
import { HtmlContent } from '../../../components/basic/html-content';
import { Hexagon } from '../../../components/cvt/data-display/hexagon';

/*---Components---*/

import { IconComponents } from '../../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

interface Description {
  descriptionAuge: string;
  descriptionTeamwork: string;
  descriptionMedallie: string;
  descriptionBike: string;
}

type HexagonCardType = {
  icon: any;
  size: number;
  alt: string;
  boxStyle: CSSProperties;
  text: string;
};

const CardKarriereHexagons = ({
  description,
}: {
  description: Description;
}) => {
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      [`@media(min-width: ${DeviceSizes.laptop})`]: {
        flexDirection: 'row',
      },
      [`@media(min-width: ${DeviceSizes.laptopL})`]: {
        flexDirection: 'row',
        alignItems: 'initial',
        height: '40em',
      },
    },
    hexagonContainer: {
      padding: '1em',
    },
    containerEye: {
      [`@media(min-width: ${DeviceSizes.laptopL})`]: {
        transform: 'translate(-7em, 0em)',
      },
    },
    containerTeamwork: {
      [`@media(min-width: ${DeviceSizes.laptopL})`]: {
        transform: 'translate(-10em, 10em)',
      },
    },
    containerMedal: {
      [`@media(min-width: ${DeviceSizes.laptopL})`]: {
        transform: 'translate(-12.5em, 16em)',
      },
    },
    containerBike: {
      [`@media(min-width: ${DeviceSizes.laptopL})`]: {
        transform: 'translate(30em, -22.5em)',
      },
      //Necessary because of "flexWrap"
      [`@media(min-width: 1885px)`]: {
        transform: 'translate(-10em, 0em)',
      },
    },
    text: {
      width: '20em',
    },
    bikeIconStyle: {
      color: '#07e5a5',
      fontSize: '6.8em',
    },
  } as const;

  const listHexagonContent: HexagonCardType[] = [
    {
      icon: IconComponents.CvtAuge,
      size: 5,
      alt: 'CvtAuge',
      boxStyle: { ...styles.hexagonContainer, ...styles.containerEye },
      text: description.descriptionAuge,
    },
    {
      icon: IconComponents.CvtTeamwork,
      size: 6,
      alt: 'CvtTeamwork',
      boxStyle: { ...styles.hexagonContainer, ...styles.containerTeamwork },
      text: description.descriptionTeamwork,
    },
    {
      icon: IconComponents.CvtMedallie,
      size: 7,
      alt: 'CvtMedallie',
      boxStyle: { ...styles.hexagonContainer, ...styles.containerMedal },
      text: description.descriptionMedallie,
    },
    {
      icon: IconComponents.DirectionsBike,
      size: 8,
      alt: 'CvtAuge',
      boxStyle: { ...styles.hexagonContainer, ...styles.containerBike },
      text: description.descriptionBike,
    },
  ];

  const createHexagonCard = (
    icon: any,
    size: number,
    alt: string,
    boxStyle: CSSProperties,
    text: string
  ) => {
    return (
      <Box component="div" sx={boxStyle}>
        <Hexagon
          componentIcon={icon}
          styleComponentIcon={{
            color: CvtColorSchema.green,
            fontSize: size * 13,
          }}
          bgColor={CvtColorSchema.white}
          hoverColor={CvtColorSchema.yellow}
          size={size}
          alt={alt}
          clickable={false}
          invert={false}
          rotate={true}
          margin={'30%'}
        />
        <Box component="div" sx={styles.text}>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(text)}
            style={CvtBasicTheme.htmlContent.general}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box component="div" sx={styles.root}>
      {listHexagonContent.map((item: any, index: number) => {
        return (
          <React.Fragment key={`hexagonKarriere-${index}`}>
            {createHexagonCard(
              item.icon,
              item.size,
              item.alt,
              item.boxStyle,
              item.text
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

/**
 * @description Returns the hexagons for the Karriere-page.
 * @see /Karriere.js
 */

export default CardKarriereHexagons;
